/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate, Link} from 'react-router-dom'
import {OrganizationPrivateRoutes, PatientPrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../patientModule/errors/ErrorsPage'
import { AuthPageOrganization } from '../organizationModule/auth'
import { AuthPagePatient} from '../patientModule/auth'
import {App} from '../App'
import { useDispatch, useSelector } from "react-redux";
import { Home } from './Home'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {tokenPatient,tokenOrganization} = useSelector((state:any) => state.admin);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {/* <Route path='error/*' element={<ErrorsPage />} /> */}
          <Route path='/' element={<Home/>} />
          <Route path='organization/*'>
            {tokenOrganization ? (
              <>
                <Route path='organization/*' element={<OrganizationPrivateRoutes />} />
                <Route index element={<Navigate to='organization/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='organization/*' element={<AuthPageOrganization />} />
                <Route path='*' element={<Navigate to='organization/' />} />
              </>
            )}
          </Route>

          <Route path='patient/*'>
            {tokenPatient ? (
              <>
                <Route path='patient/*' element={<PatientPrivateRoutes />} />
                <Route index element={<Navigate to='patient/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='patient/*' element={<AuthPagePatient />} />
                <Route path='*' element={<Navigate to='patient/' />} />
              </>
            )}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
