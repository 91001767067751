import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayoutPatient} from '../../_metronicPatient/layout/MasterLayout'
import {getCSSVariableValue} from '../../_metronicPatient/assets/ts/_utils'
import {WithChildren} from '../../_metronicPatient/helpers'
import {MasterLayoutOrganization} from '../../_metronicOrganization/layout/MasterLayout'
// import {getCSSVariableValueOrganization} from '../../_metronicOrganization/assets/ts/_utils'
// import {WithChildrenOrganization} from '../../_metronicOrganization/helpers'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PatientPrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../patientModule/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../patientModule/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../patientModule/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../patientModule/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../patientModule/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../patientModule/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayoutPatient />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='apps/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

const SuspensedViewOrganization: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

const OrganizationPrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../organizationModule/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../organizationModule/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../organizationModule/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../organizationModule/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../organizationModule/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../organizationModule/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayoutOrganization />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='builder'
          element={
            <SuspensedViewOrganization>
              <BuilderPageWrapper />
            </SuspensedViewOrganization>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedViewOrganization>
              <ProfilePage />
            </SuspensedViewOrganization>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedViewOrganization>
              <WizardsPage />
            </SuspensedViewOrganization>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedViewOrganization>
              <WidgetsPage />
            </SuspensedViewOrganization>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedViewOrganization>
              <AccountPage />
            </SuspensedViewOrganization>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedViewOrganization>
              <ChatPage />
            </SuspensedViewOrganization>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedViewOrganization>
              <UsersPage />
            </SuspensedViewOrganization>
          }
        />
        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}


export {PatientPrivateRoutes,OrganizationPrivateRoutes}
