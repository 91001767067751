import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword } from "../core/_requests";
import DataService from "../../../config/dataService";
import validator from "validator";

const initialValues = {
  email: "",
  otp: "",
  password: "",
  changepassword: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter email address")
    .min(6, "Use 6 or more characters can be entered")
    .max(50, "Max 50 characters can be entered")
    .test({
      name: "emailErr",
      message: "Please enter valid email address",
      test: (value) => validator.isEmail(value),
    }),
});

const forgotPasswordOtpSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter email address")
    .min(6, "Use 6 or more characters can be entered")
    .max(50, "Max 50 characters can be entered")
    .test({
      name: "emailErr",
      message: "Please enter valid email address",
      test: (value) => validator.isEmail(value),
    }),
  otp: Yup.string()
    .length(4, "Otp will be 4 digits")
    .required("Please enter otp"),
    password: Yup.string()
    .strict(true)
    .trim("This field can not contain space")
    .min(8, "Use 8 or more characters can be entered")
    .max(32, "Max 32 characters can be entered")
    .test({
      name: "passwordErr",
      message: "Use characters with a mix of letters, numbers & symbols",
      test: (value) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
          value as any
        ),
    })
    .required("Please enter password"),
    changepassword: Yup.string()
    .strict(true)
    .trim("This field can not contain space")
    .min(8, "Use 8 or more characters with a mix of letters, numbers & symbols")
    .max(32, "Max 32 characters can be entered")
    .required("Please enter confirm password")
    .test({
      name: "passwordErr",
      message: "Use characters with a mix of letters, numbers & symbols",
      test: (value) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
          value as any
        ),
    })
    .oneOf([Yup.ref("password")], "Password and Confirm Password didn't match"),
});

export function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: otpSent ? forgotPasswordOtpSchema : forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        setHasErrors(undefined);
        // setTimeout(() => {
        //   requestPassword(values.email)
        //     .then(({data: {result}}) => {
        //       setHasErrors(false)
        //       setLoading(false)
        //     })
        //     .catch(() => {
        //       setHasErrors(true)
        //       setLoading(false)
        //       setSubmitting(false)
        //       setStatus('The login detail is incorrect')
        //     })
        // }, 1000)
        if (otpSent) {
          const { status } = await DataService().post(
            "/patient/reset-password",
            {
              email: values.email,
              otp: values.otp,
              password: values.password,
            }
          );
          if (status === 200) {
            setOtpSent(false);
            navigate("/patient");
          }
        } else {
          const { status, data } = await DataService().post(
            "/patient/forgot-password",
            {
              email: values.email,
            }
          );
          if (status === 200) {
            setOtpSent(true);
            setHasErrors(false);
            setLoading(false);
          }
        }
      } catch (error: any) {
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        console.log(error.response.data.message);
        setStatus(error.response.data.message);
      }
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">Forgot Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          Enter your email to reset your password.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {hasErrors === false && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
            Sent password reset. Please check your email
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
        <input
          type="text"
          disabled={otpSent}
          placeholder=""
          autoComplete="off"

          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      {otpSent && (
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-gray-900 fs-6">OTP</label>
          <input
            type="text"
            placeholder=""
            maxLength={4}
            autoComplete="off"
            {...formik.getFieldProps("otp")}
            onChange={(e) => {
              const v = e.target.value as any;
              formik.setFieldValue("otp", isNaN(v) ? "" : v.trim());
            }}
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": formik.touched.otp && formik.errors.otp },
              {
                "is-valid": formik.touched.otp && !formik.errors.otp,
              }
            )}
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.otp}</span>
              </div>
            </div>
          )}
        </div>
      )}

      {otpSent && (
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Password
          </label>
          <input
            type="password"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
      )}

      {otpSent && (
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Confirm Password
          </label>
          <input
            type="password"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("changepassword")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid":
                  formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                "is-valid":
                  formik.touched.changepassword &&
                  !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
        >
          <span className="indicator-label">Submit</span>
          {loading && (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/patient">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light"
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
}
