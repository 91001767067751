import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../_metronicPatient/helpers'
import { HomeImage } from '../../assets/images/images'
import { UsersListWrapper } from '../organizationModule/apps/user-management/users-list/UsersList'


export const Home = () => {
  
  // return (
  //   <>
  //  <UsersListWrapper/>
  //   </>
  // )

  return (
    <>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
        {/* begin::Body */}
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
          {/* begin::Form */}
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            {/* begin::Wrapper */}
            <div className='w-lg-500px p-10'>
              {/* <Outlet /> */}
              <div className='fv-row mb-3'>
                <div>
                  <Link to='/patient'>
                    <div className='d-grid mb-10'>
                      <button id='kt_sign_in_submit' className='btn btn-primary'>
                        <span className='indicator-label-home'>Login as Patient</span>
                      </button>
                    </div>
                  </Link>
                </div>
                <br />

                <div>
                  <Link to='/organization'>
                    <div className='d-grid mb-10'>
                      <button id='kt_sign_in_submit' className='btn btn-primary'>
                        <span className='indicator-label-home'>Login as Health Facility</span>
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Form */}
        </div>
        {/* end::Body */}

        {/* begin::Aside */}
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{
            backgroundImage: `url(${HomeImage})`,
          }}
        >
          {/* begin::Content */}
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            {/* begin::Logo */}
            {/* <Link to='/' className='mb-12'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-75px' />
            </Link> */}
            {/* end::Logo */}

            {/* begin::Image */}
            <img
              className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
              // src={toAbsoluteUrl('/media/misc/auth-screens.png')}
              alt=''
            />
            {/* end::Image */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Aside */}
      </div>
    </>
  )
}
