import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import AdminReducer from './slices/slice';

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, AdminReducer)

export const store = configureStore({
  reducer: {
    admin: persistedReducer
  },
})
export const persistor = persistStore(store)