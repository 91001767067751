import {createSlice} from '@reduxjs/toolkit'

export const AdminSlice = createSlice({
  name: 'counter',
  initialState: {
    tokenPatient: null,
    tokenOrganization: null,
  },
  reducers: {
    patientStoreToken: (state,action) => {
        state.tokenPatient = action.payload
    },
    organizationStoreToken: (state,action) => {
        state.tokenOrganization = action.payload
    },
    logoutPatient: (state) => {
        state.tokenPatient = null
    },
    logoutOrganization: (state) => {
        state.tokenOrganization = null
    },
  },
})
export const {patientStoreToken,organizationStoreToken,logoutPatient,logoutOrganization} = AdminSlice.actions
export default AdminSlice.reducer
