import axios from 'axios'
// const API_ENDPOINT = 'http://localhost:3037/api/'
const API_ENDPOINT = 'https://phr-api.appworkdemo.com/api/'

export default function DataService(type?: any) {
  const DataService = axios.create({
    baseURL: API_ENDPOINT,
  })
  DataService.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(type)}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  return DataService
}
