/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useNavigate} from 'react-router-dom'
// import {useAuthOrganization} from '../../../../app/patientModule/auth_'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import { logoutPatient,logoutOrganization } from '../../../../app/redux/slices/slice'
import { useDispatch, useSelector } from "react-redux";

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch();
  const navigate =useNavigate();
  // const {currentOrganization, logout} = useAuthOrganization()
  const logOut=()=>{
    localStorage.removeItem('patient')
    dispatch(logoutPatient());
    navigate('/')
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >


      <div className='menu-item px-5'>
        <Link to={'/patient/patient/apps/profile/overview'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      

      <div className='menu-item px-5'>
        <a onClick={()=>logOut()} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
