import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronicPatient/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronicPatient/layout/core'
import {MasterInitPatient} from '../_metronicPatient/layout/MasterInit'
import { MasterInit } from '../_metronicOrganization/layout/MasterInit'
// import {AuthInit} from './patientModule/auth_'
import {ThemeModeProvider} from '../_metronicPatient/partials'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            {/* <AuthInit> */}
              <Outlet />
              {/* <MasterInit /> */}
              {/* <MasterInitPatient/> */}
            {/* </AuthInit> */}
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
